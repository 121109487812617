// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-de-suche-js": () => import("./../../../src/pages/de/suche.js" /* webpackChunkName: "component---src-pages-de-suche-js" */),
  "component---src-pages-en-search-js": () => import("./../../../src/pages/en/search.js" /* webpackChunkName: "component---src-pages-en-search-js" */),
  "component---src-pages-tests-search-js": () => import("./../../../src/pages/tests/search.js" /* webpackChunkName: "component---src-pages-tests-search-js" */),
  "component---src-templates-archive-index-js": () => import("./../../../src/templates/archive/index.js" /* webpackChunkName: "component---src-templates-archive-index-js" */),
  "component---src-templates-category-index-js": () => import("./../../../src/templates/category/index.js" /* webpackChunkName: "component---src-templates-category-index-js" */),
  "component---src-templates-cookbook-post-index-js": () => import("./../../../src/templates/cookbook-post/index.js" /* webpackChunkName: "component---src-templates-cookbook-post-index-js" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-templates-post-index-js": () => import("./../../../src/templates/post/index.js" /* webpackChunkName: "component---src-templates-post-index-js" */),
  "component---src-templates-recipe-index-js": () => import("./../../../src/templates/recipe/index.js" /* webpackChunkName: "component---src-templates-recipe-index-js" */),
  "component---src-templates-series-index-js": () => import("./../../../src/templates/series/index.js" /* webpackChunkName: "component---src-templates-series-index-js" */)
}

